<template>
	<b-card title="Manage Assign">
		<b-row>
			<b-col cols="7">
				<b-card>
					<div class="d-flex justify-content-between  flex-wrap mb-1" >
						<!-- sorting  -->
						<b-form-group
							label="Sort"
							label-size="sm"
							label-align-sm="left"
							label-cols-sm="2"
							label-for="sortBySelect"
							class="mr-1 mb-md-0"
						>
						<b-input-group
							size="sm"
						>
							<b-form-select
								id="sortBySelect"
								v-model="sortBy"
								:options="sortOptions"
							>
								<template #first>
									<option value="">
									none
									</option>
								</template>
							</b-form-select>
							<b-form-select
								v-model="sortDesc"
								size="sm"
								:disabled="!sortBy"
							>
								<option :value="false">
									Asc
								</option>
								<option :value="true">
									Desc
								</option>
							</b-form-select>
						</b-input-group>
						</b-form-group>

						<!-- filter -->
						<b-form-group
							label="Filter"
							label-cols-sm="2"
							label-align-sm="left"
							label-size="sm"
							label-for="filterInput"
							class="mb-0"
						>
						<b-input-group size="sm">
							<b-form-input
								id="filterInput"
								v-model="filter"
								type="search"
								placeholder="Type to Search"
							/>
							<b-input-group-append>
								<b-button
									:disabled="!filter"
									@click="filter = ''"
								>
									Clear
								</b-button>
							</b-input-group-append>
						</b-input-group>
						</b-form-group>
					</div>

					<b-table
						striped
						hover
						responsive
						selectable
						select-mode="single"
						class="position-relative"
						:per-page="perPage"
						:current-page="currentPage"
						:items="items"
						:fields="fields"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:sort-direction="sortDirection"
						:filter="filter"
						:filter-included-fields="filterOn"
						@filtered="onFiltered"
						@row-selected="onRowSelected"
					>
						<template #cell(long_lat_elev)="data">
							<label > {{data.item.current_longitude}} / {{data.item.current_latitude}} / {{data.item.station_elevation}} </label> 
						</template>
					</b-table>

					<b-card-body class="d-flex justify-content-between flex-wrap pt-0">
						<!-- page length -->
						<b-form-group
							label="Per Page"
							label-cols="6"
							label-align="left"
							label-size="sm"
							label-for="sortBySelect"
							class="text-nowrap mb-md-0 mr-1"
						>
							<b-form-select
								id="perPageSelect"
								v-model="perPage"
								size="sm"
								inline
								:options="pageOptions"
							/>
						</b-form-group>

						<!-- pagination -->
						<div>
							<b-pagination
								v-model="currentPage"
								:total-rows="totalRows"
								:per-page="perPage"
								first-number
								last-number
								prev-class="prev-item"
								next-class="next-item"
								class="mb-0"
							>
								<template #prev-text>
									<feather-icon
										icon="ChevronLeftIcon"
										size="18"
									/>
								</template>
								<template #next-text>
									<feather-icon
										icon="ChevronRightIcon"
										size="18"
									/>
								</template>
							</b-pagination>
						</div>
					</b-card-body>
				</b-card>
			</b-col>

			<b-col cols="5">
				<b-card>
					<div class="d-flex justify-content-between  flex-wrap mb-1" >
						<!-- sorting  -->
						<b-form-group
							label-size="sm"
							label-align-sm="left"
							label-cols-sm="2"
							label-for="sortBySelect"
							class="mr-1 mb-md-0"
						>
						
						</b-form-group>

						<!-- filter -->
						<b-form-group
							label="Filter"
							label-cols-sm="2"
							label-align-sm="left"
							label-size="sm"
							label-for="filterInputUser"
							class="mb-0"
						>
						<b-input-group size="sm">
							<b-form-input
								id="filterInputUser"
								v-model="filterUser"
								type="search"
								placeholder="Type to Search"
							/>
							<b-input-group-append>
								<b-button
									:disabled="!filterUser"
									@click="filterUser = ''"
								>
									Clear
								</b-button>
							</b-input-group-append>
						</b-input-group>
						</b-form-group>
					</div>

					<b-table
						striped
						hover
						responsive
						selectable
						select-mode="single"
						class="position-relative"
						:per-page="perUserPage"
						:current-page="currentUserPage"
						:items="userItems"
						:fields="userFields"
						:filter="filterUser"
						:filter-included-fields="filterUserOn"
						@filtered="onFilteredUser"
						@row-selected="onRowUserSelected"
					>
					</b-table>

					<b-card-body class="d-flex justify-content-between flex-wrap pt-0">
						<!-- page length -->
						<b-form-group
							label="Per Page"
							label-cols="6"
							label-align="left"
							label-size="sm"
							label-for="sortBySelect"
							class="text-nowrap mb-md-0 mr-1"
						>
							<b-form-select
								id="perPageUserSelect"
								v-model="perUserPage"
								size="sm"
								inline
								:options="pageUserOptions"
							/>
						</b-form-group>

						<!-- pagination -->
						<div>
							<b-pagination
								v-model="currentUserPage"
								:total-rows="totalUserRows"
								:per-page="perUserPage"
								first-number
								last-number
								prev-class="prev-item"
								next-class="next-item"
								class="mb-0"
							>
								<template #prev-text>
									<feather-icon
										icon="ChevronLeftIcon"
										size="18"
									/>
								</template>
								<template #next-text>
									<feather-icon
										icon="ChevronRightIcon"
										size="18"
									/>
								</template>
							</b-pagination>
						</div>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
//import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BCard, BRow, BCol,BCardBody, BFormGroup, BFormInput, BInputGroup, BFormSelect, BTable, BAvatar, BBadge, BInputGroupAppend, BButton, BPagination} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import metadata from "@/api/metadata";
import Service from "@/api/userservice";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BCardBody,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BFormSelect,
		BTable,
		BAvatar,
		BBadge,
		BInputGroupAppend,
		BButton,
		BPagination
	},
	data: function() {
		return {
			perPage: 5,
			pageOptions: [5, 10, 15],
			totalRows: 1,
			currentPage: 1,
			sortBy: '',
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
			filterOn: [],
			infoModal: {
				id: 'info-modal',
				title: '',
				content: '',
			},
			fields: [
				{ key: 'id', label: 'Id', sortable: true},
				{ key: 'station_name', label: 'Station Name', sortable: true },
				{ key: 'long_lat_elev', label: 'Long/Lat/Elev'},
			],
			items: [],
			status: [{
					1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
				},
				{
					1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
				}],

			filterUser: null,
			filterUserOn: [],
			perUserPage: 5,
			pageUserOptions: [5, 10, 15],
			totalUserRows: 1,
			currentUserPage: 1,
			userFields: [
				{ key: 'id', label: 'ID', sortable: true},
				{ key: 'name', label: 'Nama'},
			],
			userItems: [],
		};
	},
	computed: {
		sortOptions() {
		// Create an options list from our fields
		return this.fields
			.filter(f => f.sortable)
			.map(f => ({ text: f.label, value: f.key }))
		},
	},
	mounted() {
		// Set the initial number of items
		// this.totalRows = this.items.length
		this.listAllStasiun();
		this.listAllUser();
	},
	methods: {
		info(item, index, button) {
			this.infoModal.title = `Row index: ${index}`
			this.infoModal.content = JSON.stringify(item, null, 2)
			this.$root.$emit('bv::show::modal', this.infoModal.id, button)
		},
		resetInfoModal() {
			this.infoModal.title = ''
			this.infoModal.content = ''
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		async listAllStasiun() {			
			try {
				const { data } = await metadata.getStationWithLimit();

				for (var i = 0; i < data.length; i++) {
					const item = {
						id: data[i].station_id,
						path: data[i].path,
						station_name: data[i].station_name,
						station_wmo_id: data[i].station_wmo_id,
						station_elevation: data[i].station_elevation,
						current_latitude: data[i].current_latitude,
						current_longitude: data[i].current_longitude
					};
					this.items.push(item);
				}
				this.totalRows = this.items.length

			} catch (e) {
				console.log(e);
			}
		},

		onFilteredUser(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalUserRows = filteredItems.length
			this.currentUserPage = 1
		},

		async listAllUser() {
			this.showLoading = true;
			try {
				Service.getListUser()
				.then((response) => {
					this.showLoading = false;
					// console.log("list-users: ", response.data);
					for (var i = 0; i < response.data.length; i++) {
						let name = response.data[i]['fullname'] != '' && response.data[i]['fullname'] != null ? response.data[i]['fullname'] : response.data[i]['@name']
						this.userItems.push({id:response.data[i].id, name: name});
					}
					// this.userItems = response.data;
					this.totalUserRows = this.userItems.length;
				})
				.catch((error) => {
					this.showLoading = false;
					console.log("Eee : ", error.response.status);
				});
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
		},

		onRowSelected(items) {
			// console.log('items', items)
		},

		onRowUserSelected(items) {
			// console.log('user items', items)
		}
	},
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
